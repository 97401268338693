import CurrentUser from "@components/CurrentUser";
import {
  Flex,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Portal,
  Heading,
  Icon,
  Link,
  PopoverFooter,
} from "@chakra-ui/react";
import { useMsal } from "@azure/msal-react";
import { EnvironmentPicker } from "@env/EnvironmentPicker";
import { MSDataPrivacyLink } from "@components/MSDataPrivacyLink";
import { ActiveAccountPicker } from "./ActiveAccountPicker";
import { VscBug, VscQuestion, VscSignOut } from "react-icons/vsc";
import { ColorModeSwitcher } from "@theme/ColorModeSwitcher";
import { CustomBox } from "@components/CustomBox";
import { PromptValue } from "@azure/msal-browser";
import { defaultRequest, getTenantAuthority } from "@auth/auth";
import useAccountStore from "@components/graph-picker/account-store";

// TODO: this should be an app config value...
export const bugLink =
  "https://dev.azure.com/xboxstudios/Hermes/_workitems/create/Bug?templateId=31ce6ee5-6119-4a6a-8858-e2a29c7d7d6c&ownerId=11d6b652-be9a-40ce-a73b-9e9ae9c5a0cd";

function UserMenu() {
  const msalContext = useMsal();
  const { preferredAccount } = useAccountStore();

  const logoutAll = async () => {
    // eslint-disable-next-line no-restricted-globals
    const shouldSignOut = confirm(
      "Are you sure you want to sign out of all accounts?"
    );
    if (shouldSignOut) {
      await msalContext.instance.clearCache();
      await msalContext.instance.loginRedirect({
        ...defaultRequest,
        prompt: PromptValue.SELECT_ACCOUNT,
        account: preferredAccount,
        authority: getTenantAuthority(preferredAccount?.tenantId),
      });
    }
  };

  return (
    <Popover placement="bottom-end" size="md" boundary="clippingParents">
      <PopoverTrigger>
        <Button padding={2} variant="ghost">
          <CurrentUser />
        </Button>
      </PopoverTrigger>

      <Portal>
        <PopoverContent borderColor="neutral.600" boxShadow="xl">
          <PopoverBody
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            py={4}
            gap={4}
          >
            <CustomBox>
              <Heading size="sm" fontWeight="semibold" color="neutral.50">
                Theme
              </Heading>
              <Flex direction="column" px={1} py={2}>
                <ColorModeSwitcher />
              </Flex>
            </CustomBox>
            <CustomBox>
              <Heading size="sm" fontWeight="semibold" color="neutral.50">
                Environment
              </Heading>
              <Flex direction="column" px={1} py={2}>
                <EnvironmentPicker />
              </Flex>
            </CustomBox>
            <CustomBox>
              <Flex justify="space-between" align="center" pb={2}>
                <Heading size="sm" fontWeight="semibold" color="neutral.50">
                  Accounts
                </Heading>
              </Flex>
              <ActiveAccountPicker />
              <Button
                size="sm"
                w="full"
                mt={4}
                variant="ghost"
                color="neutral.50"
                leftIcon={<VscSignOut />}
                onClick={logoutAll}
                title="Sign out of all accounts"
              >
                Sign out of all accounts
              </Button>
            </CustomBox>
          </PopoverBody>
          <PopoverFooter>
            <Flex alignItems="center" gap={4} py={2}>
              <Button
                as={Link}
                variant="link"
                href={bugLink}
                target="_blank"
                size="sm"
                fontSize="12px"
                color="neutral.50"
                leftIcon={<Icon as={VscBug} color="red.500" />}
              >
                Report a bug
              </Button>
              <Button
                as={Link}
                href="https://dev.azure.com/xboxstudios/Hermes/_wiki/wikis/Docs/1838/FAQ"
                target="_blank"
                variant="link"
                size="sm"
                fontSize="12px"
                color="neutral.50"
                leftIcon={<Icon as={VscQuestion} color="blue.500" />}
              >
                FAQ
              </Button>
              <Button size="sm" variant="link">
                <MSDataPrivacyLink
                  fontSize="12px"
                  color="neutral.50"
                  target="_blank"
                />
              </Button>
            </Flex>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export default UserMenu;
