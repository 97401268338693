import { gRPCError } from "@components/register/ApprovalErrorMessage";
import {
  getArchive,
  getArchivesList,
  getDepot,
  getDepotsList,
  getStorageAccountsList,
} from "@utils/hermes-api/chunkstore";
import { ListQueryOptions } from "@utils/hermes-api/types";
import {
  Archive,
  Depot,
  ListArchivesResponse,
  ListDepotsResponse,
  ListStorageAccountsResponse,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/chunkstore/v1/chunkstore_pb";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { archiveKeys, depotKeys, storageAccountKeys } from "./query-keys";
import { Sort } from "./utils";

export const useDepotQuery = (
  depotName: string,
  queryOptions: UseQueryOptions<Depot, gRPCError> = {}
) => {
  return useQuery({
    queryKey: depotKeys.single(depotName),
    queryFn: () => getDepot(depotName),
    ...queryOptions,
  });
};

export interface DepotSort extends Sort<"name"> {}

export const sortDepots = (
  depots: Depot[],
  { sortField = "name", asc = true }: DepotSort
) => {
  switch (sortField) {
    case "name":
      return [...depots].sort((a, b) => {
        const aName = a.getDisplayName() || a.getId();
        const bName = b.getDisplayName() || b.getId();
        return aName.localeCompare(bName) * (asc ? 1 : -1);
      });
    default:
      console.warn(`Unknown depot sort field: ${sortField}`);
      return depots;
  }
};

export const useDepotListQuery = (
  orgName: string,
  filters: ListQueryOptions = {},
  queryOptions: UseQueryOptions<ListDepotsResponse, gRPCError> = {}
) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: depotKeys.listFiltered(orgName, filters),
    queryFn: async () => {
      // NOTE: We're fetching all of the pages here because
      // the API cannot guarantee page sizes. This is a temporary
      // workaround until the API can provide a consistent page size.
      // see: https://dev.azure.com/xboxstudios/Hermes/_workitems/edit/68708
      const depots = [];
      let res = await getDepotsList(orgName, filters);
      depots.push(...res.getDepotsList());
      while (res.getNextPageToken()) {
        res = await getDepotsList(orgName, {
          ...filters,
          pageToken: res.getNextPageToken(),
        });
        depots.push(...res.getDepotsList());
      }
      res.setDepotsList(depots);

      return res;
    },
    ...queryOptions,
    onSuccess: (res) => {
      const depot = res.getDepotsList();
      // cache each depot individually
      for (const d of depot) {
        queryClient.setQueryData(depotKeys.single(d.getName()), d);
      }

      const { sortField, asc } = filters;
      // Locally sort depots by explicit sort field, user preference, or
      // default to sorting by display name
      // TODO: This is a temporary solution until we can sort on the server
      res.setDepotsList(sortDepots(res.getDepotsList(), { sortField, asc }));

      if (queryOptions.onSuccess) {
        queryOptions.onSuccess(res);
      }
    },
  });
};

export const useDepotMutation = <TVariables>(
  mutateOptions: UseMutationOptions<Depot, gRPCError, TVariables> = {}
) => {
  const queryClient = useQueryClient();
  return useMutation<Depot, gRPCError, TVariables>({
    ...mutateOptions,
    onSuccess: (depot, variables, context) => {
      // set/update individual cached depot
      queryClient.invalidateQueries(depotKeys.single(depot.getName()), {
        refetchActive: true,
      });

      // invalidate the list queries
      queryClient.invalidateQueries(depotKeys.allLists, {
        exact: false,
        refetchActive: true,
      });

      if (mutateOptions.onSuccess) {
        mutateOptions.onSuccess(depot, variables, context);
      }
    },
  });
};

export const useStorageAccountListQuery = (
  orgName: string,
  filters: ListQueryOptions = {},
  queryOptions: UseQueryOptions<ListStorageAccountsResponse, gRPCError> = {}
) => {
  return useQuery({
    queryKey: storageAccountKeys.listFiltered(orgName, filters),
    queryFn: () => getStorageAccountsList(orgName, filters),
    ...queryOptions,
  });
};

export const useArchiveQuery = (
  archiveName: string,
  queryOptions: UseQueryOptions<Archive, gRPCError> = {}
) => {
  return useQuery({
    queryKey: archiveKeys.single(archiveName),
    queryFn: () => getArchive(archiveName),
    ...queryOptions,
  });
};

export const useArchiveListQuery = (
  depotName: string,
  filters: ListQueryOptions = {},
  queryOptions: UseQueryOptions<ListArchivesResponse, gRPCError> = {}
) => {
  const queryClient = useQueryClient();
  const queryKey = archiveKeys.listFiltered(depotName, filters);
  return useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      // NOTE: We're fetching all of the pages here because
      // the API cannot guarantee page sizes. This is a temporary
      // workaround until the API can provide a consistent page size.
      // see: https://dev.azure.com/xboxstudios/Hermes/_workitems/edit/68708
      const archives = [];
      let res = await getArchivesList(depotName, filters);
      archives.push(...res.getArchivesList());
      while (res.getNextPageToken()) {
        res = await getArchivesList(depotName, {
          ...filters,
          pageToken: res.getNextPageToken(),
        });
        archives.push(...res.getArchivesList());
      }
      res.setArchivesList(archives);

      return res;
    },
    ...queryOptions,
    onSuccess: (res) => {
      const archives = res.getArchivesList();
      // cache each archive individually
      for (const a of archives) {
        queryClient.setQueryData(archiveKeys.single(a.getName()), a);
      }

      if (queryOptions.onSuccess) {
        queryOptions.onSuccess(res);
      }
    },
  });
};

export function sortArchiveListByCreateDate(res: ListArchivesResponse) {
  const archives = res.getArchivesList();
  const sortedArchives = archives.sort(
    (a, b) =>
      (b.getCreateTime()?.getSeconds() || 0) -
      (a.getCreateTime()?.getSeconds() || 0)
  );
  res.setArchivesList(sortedArchives);
  return res;
}

export const useArchiveMutation = <TVariables>(
  mutateOptions: UseMutationOptions<Archive, gRPCError, TVariables> = {}
) => {
  const queryClient = useQueryClient();
  return useMutation<Archive, gRPCError, TVariables>({
    ...mutateOptions,
    onSuccess: (archive, variables, context) => {
      // set/update individual cached archive
      queryClient.invalidateQueries(archiveKeys.single(archive.getName()), {
        refetchActive: true,
      });

      // invalidate the list queries
      queryClient.invalidateQueries(archiveKeys.allLists, {
        exact: false,
        refetchActive: true,
      });

      if (mutateOptions.onSuccess) {
        mutateOptions.onSuccess(archive, variables, context);
      }
    },
  });
};
