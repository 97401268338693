import { AccountInfo } from "@azure/msal-browser";
import { getHermesEnvironment } from "@env/envrionment";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface TenantState {
  account: AccountInfo | null;
  setAccount(account: AccountInfo): void;
  // This lets the user choose an account to try to log in to
  // if they sign out or their refresh token expires. It's mainly
  // used as a workaround for users who only have access to Hermes
  // via a guest account.
  preferredAccount: AccountInfo | undefined;
  setPreferredAccount(account: AccountInfo | undefined): void;
}

/**
 * Global state to select an acount other than the active account in MSAL.
 * Useful when explicitly making requests to other tenants (ex. MS Graph)
 */
const useAccountStore = create<TenantState>()(
  persist(
    (set) => ({
      account: null,
      setAccount: (a) => set((state) => ({ ...state, account: a })),
      preferredAccount: undefined,
      setPreferredAccount: (a) =>
        set((state) => ({ ...state, preferredAccount: a })),
    }),
    {
      name: `hermes.${getHermesEnvironment().name}.accountStore`,
    }
  )
);

export default useAccountStore;
