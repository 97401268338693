import { Error as gRPCError } from "grpc-web";
import { getOrganization, searchOrganizations } from "@utils/hermes-api/org";
import {
  Organization,
  SearchOrganizationsResponse,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/directory/v1/organizations_pb";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { orgKeys } from "./query-keys";
import { ListQueryOptions } from "@utils/hermes-api/types";

export const useOrgQuery = (
  orgName: string,
  queryOptions: UseQueryOptions<Organization, gRPCError> = {}
) => {
  return useQuery({
    queryKey: orgKeys.single(orgName),
    queryFn: () => getOrganization(orgName),
    ...queryOptions,
  });
};

export const useOrgListQuery = (
  queryOptions: UseQueryOptions<SearchOrganizationsResponse, gRPCError> = {},
  filters: ListQueryOptions = {}
) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: orgKeys.allLists,
    queryFn: async () => {
      // NOTE: We're fetching all of the pages here because
      // the API cannot guarantee page sizes. This is a temporary
      // workaround until the API can provide a consistent page size.
      // see: https://dev.azure.com/xboxstudios/Hermes/_workitems/edit/68708
      const orgs = [];
      let res = await searchOrganizations(filters);
      orgs.push(...res.getOrganizationsList());
      while (res.getNextPageToken()) {
        res = await searchOrganizations({
          ...filters,
          pageToken: res.getNextPageToken(),
        });
        orgs.push(...res.getOrganizationsList());
      }
      res.setOrganizationsList(orgs);

      return res;
    },
    select: sortOrgListByName,
    ...queryOptions,
    onSuccess: (res) => {
      const orgs = res.getOrganizationsList();
      for (const o of orgs) {
        queryClient.setQueryData(orgKeys.single(o.getName()), o);
      }

      if (queryOptions.onSuccess) {
        queryOptions.onSuccess(res);
      }
    },
  });
};

export function sortOrgListByName(orgs: SearchOrganizationsResponse) {
  const sortedOrgs = orgs.getOrganizationsList().sort((a, b) => {
    return a.getName().localeCompare(b.getName());
  });
  orgs.setOrganizationsList(sortedOrgs);
  return orgs;
}

export const useOrgMutation = <TVariables>(
  mutateOptions: UseMutationOptions<Organization, gRPCError, TVariables> = {}
) => {
  const queryClient = useQueryClient();
  return useMutation<Organization, gRPCError, TVariables>({
    ...mutateOptions,
    onSuccess: (org, variables, context) => {
      queryClient.setQueryData(orgKeys.single(org.getName()), org);

      // set/update individual cached org
      queryClient.invalidateQueries(orgKeys.allLists, {
        exact: false,
        refetchActive: true,
      });

      if (mutateOptions.onSuccess) {
        mutateOptions.onSuccess(org, variables, context);
      }
    },
  });
};
