import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import { VscChevronDown } from "react-icons/vsc";
import { EnvironmentBadge } from "./EnvironmentBadge";
import {
  environments,
  getHermesEnvironment,
  HermesEnvironment,
  setHermesEnvironment,
} from "./envrionment";

const envList = Object.values(environments);

export const EnvironmentPicker = () => {
  const currentEnv = getHermesEnvironment();
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<VscChevronDown />}
        title="Change Hermes Environment"
      >
        <Flex align="center" gap="2">
          <EnvironmentBadge env={currentEnv} /> <Text>Choose Environment</Text>
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          defaultValue={currentEnv.name}
          title="Hermes Environment"
          type="radio"
          onChange={(v) => setHermesEnvironment(v as HermesEnvironment)}
        >
          {envList
            .filter((e) => e.info.display)
            .map((e) => (
              <MenuItemOption key={e.name} value={e.name}>
                <Box>
                  <EnvironmentBadge env={e} />
                  <Text fontSize="sm" color="neutral.200">
                    {e.hermesApi.endpoint}
                  </Text>
                </Box>
              </MenuItemOption>
            ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
