import { AuthError, InteractionType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { ReactNode, useEffect } from "react";
import LoadSplash from "./LoadSplash";
import { Button, Flex } from "@chakra-ui/react";
import { defaultRequest } from "@auth/auth";
import useAccountStore from "./graph-picker/account-store";
import Header from "./header";

const isRedirectError = (error: AuthError | null) => {
  if (
    !!error &&
    (error instanceof InteractionRequiredAuthError ||
      // Handle issues when refreshing expired tokens from different resources
      // ex: after an environment switch
      error.errorMessage.match(/^90009/))
  ) {
    return true;
  }

  return false;
};

/**
 * Verifies that a user is logged in. If not it will invoke silent login
 * and then, if unsuccessful, redirect login.
 * Using since <MsalAuthenticationTemplate> doesn't support silent logins...
 * @returns
 */
export const RequireLogin = ({ children }: { children: ReactNode }) => {
  const { preferredAccount } = useAccountStore();
  const { login, error } = useMsalAuthentication(InteractionType.Silent, {
    ...defaultRequest,
    account: preferredAccount,
  });
  const msal = useMsal();

  useEffect(() => {
    if (isRedirectError(error)) {
      login(InteractionType.Redirect, {
        ...defaultRequest,
        account: preferredAccount,
      });
    }
  }, [error, login, preferredAccount]);

  if (error) {
    if (isRedirectError(error)) {
      return <LoadSplash message="Signing in..." />;
    }

    return (
      <Flex flexDir="column" h="100vh">
        <Header h="16" />

        <LoadSplash message={"Signin Error: " + error}>
          <Button
            onClick={async () => {
              // In the instance there is a non-interactionRequiredError
              // the user may end up in a situation where they can't login.
              // It's easiest to just clear the cache and have the user login
              // with fresh start.
              await msal.instance.clearCache();
              login(InteractionType.Redirect, {
                ...defaultRequest,
                account: preferredAccount,
              });
            }}
            bgColor="hermes.400"
            color="black"
          >
            Retry
          </Button>
        </LoadSplash>
      </Flex>
    );
  }

  return (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoadSplash message="Signing in..." />
      </UnauthenticatedTemplate>
    </>
  );
};
