import { isAccessDenied } from "@components/permissions/AccessDeniedFallback";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Skeleton,
} from "@chakra-ui/react";
import { getVersionBuildId } from "@components/version";
import { useDeviceQuery } from "@context/http-cache/hermes-queries/device";
import { useOrgQuery } from "@context/http-cache/hermes-queries/org";
import {
  useProductQuery,
  useChannelQuery,
  useVersionQuery,
} from "@context/http-cache/hermes-queries/product";
import { useDepotQuery } from "@context/http-cache/hermes-queries/chunkstore";
import { Link, Route, Switch, useLocation } from "react-router-dom";

interface LinkSection {
  link: string;
  label: string;
}

const HeaderBreadcrumbs = () => {
  const location = useLocation();
  const pathParts: string[] = location.pathname
    .split("/")
    .filter((v?: string) => !!v);
  let pathData: LinkSection[] = pathParts.map((v) => ({ link: v, label: v }));

  const type = getTypeFromName(pathParts);

  const hasOrg = ["org", "product", "channel", "version"].includes(type);
  const hasDevice = type === "device";
  const hasDepot = type === "depot";
  const hasProduct = ["product", "channel", "version"].includes(type);
  const hasChannel = type === "channel";
  const hasVersion = type === "version";

  const deviceQuery = useDeviceQuery(
    pathData
      .slice(0, 2)
      .map((v) => v.link)
      .join("/"),
    { enabled: hasDevice && pathParts.length > 1 }
  );
  const orgQuery = useOrgQuery(
    pathData
      .slice(0, 2)
      .map((v) => v.link)
      .join("/"),
    { enabled: hasOrg && pathParts.length > 1 }
  );
  const depotQuery = useDepotQuery(
    pathData
      .slice(0, 4)
      .map((v) => v.link)
      .join("/"),
    { enabled: hasDepot && pathParts.length > 3 }
  );
  const productQuery = useProductQuery(
    pathData
      .slice(0, 4)
      .map((v) => v.link)
      .join("/"),
    { enabled: hasProduct && pathParts.length > 3 }
  );
  const channelQuery = useChannelQuery(
    pathData
      .slice(0, 6)
      .map((v) => v.link)
      .join("/"),
    { enabled: hasChannel }
  );
  const versionQuery = useVersionQuery(
    pathData
      .slice(0, 6)
      .map((v) => v.link)
      .join("/"),
    { enabled: hasVersion }
  );

  if (
    !hasDevice &&
    !hasOrg &&
    !hasDepot &&
    !hasProduct &&
    !hasChannel &&
    !hasVersion
  ) {
    return null;
  }

  if (
    [
      orgQuery.error,
      depotQuery.error,
      productQuery.error,
      channelQuery.error,
      versionQuery.error,
      deviceQuery.error,
    ].some((e) => isAccessDenied(e))
  ) {
    return null;
  }

  const isHeaderLoading =
    orgQuery.isLoading ||
    deviceQuery.isLoading ||
    depotQuery.isLoading ||
    productQuery.isLoading ||
    channelQuery.isLoading ||
    versionQuery.isLoading;

  if (orgQuery.isSuccess && hasOrg) {
    pathData[1].label = orgQuery.data.getDisplayName();
  }
  if (depotQuery.isSuccess && hasDepot) {
    pathData[3].label = depotQuery.data.getDisplayName();
  }
  if (productQuery.isSuccess && hasProduct) {
    pathData[3].label = productQuery.data.getDisplayName();
  }
  if (channelQuery.isSuccess && hasChannel) {
    pathData[5].label = channelQuery.data.getDisplayName();
  }
  if (versionQuery.isSuccess && hasVersion) {
    const versionDisplayName = getVersionBuildId(versionQuery.data);
    if (versionDisplayName) {
      pathData[5].label = versionDisplayName;
    }
  }
  if (deviceQuery.isSuccess && hasDevice) {
    pathData[1].label = deviceQuery.data.getDisplayName();
  }

  // Turn the path parts into LinkSections with the
  // appropriate URL for each link
  pathData = pathData.reduce((acc, v, i) => {
    let oldVal = acc[i - 1]?.link || "";
    const pt = oldVal + "/" + v.link;
    acc[i] = {
      link: pt,
      label: v.label,
    };
    return acc;
  }, [] as LinkSection[]);

  if (hasOrg) {
    // remove "orgs" from breadcrumbs since it's not really useful
    pathData = pathData.slice(1);
  }

  return (
    <Switch>
      <Route path="/">
        <Skeleton isLoaded={!isHeaderLoading}>
          <Breadcrumb>
            {pathData.map((p) => (
              <BreadcrumbItem key={p.link}>
                <BreadcrumbLink as={Link} to={p.link}>
                  {p.label}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </Skeleton>
      </Route>
    </Switch>
  );
};

export default HeaderBreadcrumbs;

/**
 * Returns the type of the resource
 * @param nameParts
 * @returns
 */
function getTypeFromName(nameParts: string[]) {
  if (
    nameParts.length > 5 &&
    nameParts[0] === "orgs" &&
    nameParts[2] === "products" &&
    nameParts[4] === "versions"
  ) {
    return "version";
  }
  if (
    nameParts.length > 5 &&
    nameParts[0] === "orgs" &&
    nameParts[2] === "products" &&
    nameParts[4] === "channels"
  ) {
    return "channel";
  }
  if (
    nameParts.length > 3 &&
    nameParts[0] === "orgs" &&
    nameParts[2] === "depots"
  ) {
    return "depot";
  }
  if (
    nameParts.length > 3 &&
    nameParts[0] === "orgs" &&
    nameParts[2] === "products"
  ) {
    return "product";
  }
  if (nameParts.length > 1 && nameParts[0] === "orgs") {
    return "org";
  }
  if (nameParts.length > 1 && nameParts[0] === "devices") {
    return "device";
  }
  console.warn(
    `Could not determine resource type from name ${nameParts.join("/")}`
  );
  return "";
}
