import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import "./styles/scrollbars.css";
import "./styles/animations.css";
import { MsalHistoryHandler } from "@components/MsalHistoryHandler";
import { RequireLogin } from "@components/RequireLogin";
import LoggingContextProvider from "@logs/LoggingContext";
import { HttpCacheContext } from "@context/http-cache/HttpCacheContext";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { DynamicColorMode } from "@theme/DynamicColorMode";
import loadable from "@loadable/component";
import { addHermesClassExtensions } from "@utils/hermes-api/prototype-extensions";
// see: https://github.com/TanStack/router/issues/227
import "regenerator-runtime";
import { AdminConsentPage } from "@pages/AdminConsentPage";
import { Helmet } from "react-helmet";
import { StrictMode } from "react";
import { IPublicClientApplication } from "@azure/msal-browser";

const AsyncAuthContent = loadable(
  () => import("./AuthorizedContent" /* webpackChunkName: "post-auth" */)
);

addHermesClassExtensions();

export const App = ({ pca }: { pca: IPublicClientApplication }) => (
  <StrictMode>
    <ChakraProvider theme={theme}>
      <DynamicColorMode>
        <HttpCacheContext>
          <BrowserRouter>
            <LoggingContextProvider>
              <MsalHistoryHandler pca={pca}>
                <Switch>
                  <Route path="/adminconsent">
                    <Helmet>
                      <title>Admin Consent</title>
                    </Helmet>
                    <AdminConsentPage />
                  </Route>
                  <Route path="*">
                    <RequireLogin>
                      <AsyncAuthContent />
                    </RequireLogin>
                  </Route>
                </Switch>
              </MsalHistoryHandler>
            </LoggingContextProvider>
          </BrowserRouter>
        </HttpCacheContext>
      </DynamicColorMode>
    </ChakraProvider>
  </StrictMode>
);
