import { FieldMask } from "@xboxstudios/hermes-apis/google/protobuf/field_mask_pb";
import {
  Archive,
  CreateArchiveRequest,
  CreateDepotRequest,
  DeleteArchiveRequest,
  DeleteDepotRequest,
  Depot,
  GetArchiveRequest,
  GetDepotRequest,
  ListArchivesRequest,
  ListDepotsRequest,
  ListStorageAccountsRequest,
  UndeleteArchiveRequest,
  UndeleteDepotRequest,
  UpdateArchiveRequest,
  UpdateDepotRequest,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/chunkstore/v1/chunkstore_pb";
import { HermesApiPermissions } from "./api-permissions";
import { getHermesClient, getMetadata } from "./client";
import { ListQueryOptions } from "./types";

const getChunkstorePromiseClient = () => {
  return getHermesClient().chunkStorePromiseClient;
};

export const getStorageAccountsList = async (
  parent: string,
  options: ListQueryOptions = {}
) => {
  const client = getChunkstorePromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.chunkStore]);
  const reqBody = new ListStorageAccountsRequest();
  reqBody.setParent(parent);
  if (options.pageSize) {
    reqBody.setPageSize(options.pageSize);
  }
  return client.listStorageAccounts(reqBody, metadata);
};

export const getDepot = async (depotName: string) => {
  const client = getChunkstorePromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.chunkStore]);
  const reqBody = new GetDepotRequest();
  reqBody.setName(depotName);
  return client.getDepot(reqBody, metadata);
};

export const getDepotsList = async (
  parent: string,
  options: ListQueryOptions = {}
) => {
  const client = getChunkstorePromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.chunkStore]);
  const reqBody = new ListDepotsRequest();
  reqBody.setParent(parent);
  if (options.showDeleted) {
    reqBody.setShowDeleted(options.showDeleted);
  }
  if (options.pageSize) {
    reqBody.setPageSize(options.pageSize);
  }
  if (options.pageToken) {
    reqBody.setPageToken(options.pageToken);
  }
  return client.listDepots(reqBody, metadata);
};

export const createDepot = async (
  parent: string,
  newDepot: Depot,
  depotId?: string
) => {
  const client = getChunkstorePromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.chunkStore]);
  const reqBody = new CreateDepotRequest();
  reqBody.setDepot(newDepot);
  reqBody.setParent(parent);
  if (depotId) {
    reqBody.setDepotId(depotId);
  }
  return client.createDepot(reqBody, metadata);
};

export const updateDepot = async (depotEdits: Depot, updatePaths: string[]) => {
  const client = getChunkstorePromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.chunkStore]);
  const reqBody = new UpdateDepotRequest();
  const updateMask = new FieldMask();
  if (updatePaths) {
    updateMask.setPathsList(updatePaths);
  }
  reqBody.setDepot(depotEdits);
  // @ts-ignore
  reqBody.setUpdateMask(updateMask);
  return client.updateDepot(reqBody, metadata);
};

export const deleteDepot = async (depotName: string) => {
  const metadata = await getMetadata([HermesApiPermissions.chunkStore]);
  const client = getChunkstorePromiseClient();
  const req = new DeleteDepotRequest();
  req.setName(depotName);
  return client.deleteDepot(req, metadata);
};

export const undeleteDepot = async (depotName: string) => {
  const metadata = await getMetadata([HermesApiPermissions.chunkStore]);
  const client = getChunkstorePromiseClient();
  const req = new UndeleteDepotRequest();
  req.setName(depotName);
  return client.undeleteDepot(req, metadata);
};

/**
 * Gets an archive by name. Optionally provide a content token to access the archive's content.
 *
 * @param archiveName Name of archive to retrieve
 * @param contentToken An optional content token to access the archive's content. Tokens are generated
 * by the API via `generateContentToken()` for a specific version.
 * @returns
 */
export const getArchive = async (
  archiveName: string,
  contentToken?: string
) => {
  const client = getChunkstorePromiseClient();
  const headers = !!contentToken
    ? { Authorization: `Bearer ${contentToken}` }
    : undefined;
  const metadata = await getMetadata(
    [HermesApiPermissions.chunkStore],
    headers
  );
  const reqBody = new GetArchiveRequest();
  reqBody.setName(archiveName);
  return client.getArchive(reqBody, metadata);
};

export const getArchivesList = async (
  depotName: string,
  options: ListQueryOptions = {}
) => {
  const client = getChunkstorePromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.chunkStore]);
  const reqBody = new ListArchivesRequest();
  reqBody.setParent(depotName);
  if (options.showDeleted) {
    reqBody.setShowDeleted(options.showDeleted);
  }
  if (options.pageSize) {
    reqBody.setPageSize(options.pageSize);
  } else {
    // Temp: set largest page size until API can return
    // archives in chronological order
    reqBody.setPageSize(1000);
  }
  if (options.pageToken) {
    reqBody.setPageToken(options.pageToken);
  }
  return client.listArchives(reqBody, metadata);
};

export const createArchive = async (parent: string, newArchive: Archive) => {
  const client = getChunkstorePromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.chunkStore]);
  const reqBody = new CreateArchiveRequest();
  reqBody.setArchive(newArchive);
  reqBody.setParent(parent);
  return client.createArchive(reqBody, metadata);
};

export const updateArchive = async (
  archiveEdits: Archive,
  updatePaths: string[]
) => {
  const client = getChunkstorePromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.chunkStore]);
  const reqBody = new UpdateArchiveRequest();
  const updateMask = new FieldMask();
  if (updatePaths) {
    updateMask.setPathsList(updatePaths);
  }
  reqBody.setArchive(archiveEdits);
  // @ts-ignore
  reqBody.setUpdateMask(updateMask);
  return client.updateArchive(reqBody, metadata);
};

export const deleteArchive = async (archiveName: string) => {
  const metadata = await getMetadata([HermesApiPermissions.chunkStore]);
  const client = getChunkstorePromiseClient();
  const req = new DeleteArchiveRequest();
  req.setName(archiveName);
  return client.deleteArchive(req, metadata);
};

export const undeleteArchive = async (archiveName: string) => {
  const metadata = await getMetadata([HermesApiPermissions.chunkStore]);
  const client = getChunkstorePromiseClient();
  const req = new UndeleteArchiveRequest();
  req.setName(archiveName);
  return client.undeleteArchive(req, metadata);
};
