import { useMsal } from "@azure/msal-react";
import { Box, CenterProps, Image, Flex, Heading, Text } from "@chakra-ui/react";
import { CustomBox } from "@components/CustomBox";
import { TenantDisplayName } from "@components/graph-picker/TenantDisplayName";
import { ActiveAccountPicker } from "@components/header/ActiveAccountPicker";
import { Error } from "grpc-web";
import { FC, ReactNode } from "react";

import shieldLock from "../../assets/shield-lock.svg";

const AccessDeniedFallbackDefault = () => {
  const msal = useMsal();
  const activeAccount = msal.instance.getActiveAccount();

  return (
    <Flex gap={4} overflowY="auto">
      {/* image... */}
      <Box maxW={64}>
        <Image src={shieldLock} h={20} />
      </Box>
      <Flex direction="column" gap={4} maxW="md" fontSize="sm">
        <Heading size="md">Insufficient Permissions</Heading>
        <Text>
          Your account doesn't have the permissions required to view this
          content. Make sure you're using the correct account, your account has
          access to this resource, and that the resource URL is correct.
        </Text>
        {!!activeAccount && (
          <CustomBox
            variant="card"
            p={4}
            display="flex"
            flexDirection="column"
            gap={4}
          >
            <Heading size="md">Verify Correct Account</Heading>
            <Text fontSize="sm">
              You may be seeing this message because you're not logged in to the
              correct account in the correct tenant.
            </Text>
            <Text>You're currently using Hermes as: </Text>
            <Box pl={4}>
              <Flex align="center" gap={2}>
                <Text w="20">Username: </Text>
                <Text fontWeight="semibold">{activeAccount.username}</Text>
              </Flex>
              <Flex align="center" gap={2}>
                <Text w="20">Object ID: </Text>
                <Text fontWeight="semibold">
                  {activeAccount.localAccountId}
                </Text>
              </Flex>
              <Flex align="center" gap={2}>
                <Text w="20">Tenant: </Text>
                <TenantDisplayName tenantId={activeAccount.tenantId} />
              </Flex>
              <Flex align="center" gap={2}>
                <Text w="20">Tenant ID: </Text>
                <Text fontWeight="semibold">{activeAccount.tenantId}</Text>
              </Flex>
            </Box>
            <Text>
              If you need to log in to a specific tenant (common for vendors and
              guest account users) use the "log in to tenant" button below. If
              you're not sure which account or tenant to use, contact the person
              who granted you access to the Hermes resource you're attempting to
              access.
            </Text>
            <ActiveAccountPicker />
          </CustomBox>
        )}
      </Flex>
    </Flex>
  );
};

// gRPC Code for Permission Denied
// see: https://grpc.github.io/grpc/core/md_doc_statuscodes.html
export const isAccessDenied = (err: Error | null) => err && err.code === 7;

export interface AccessDeniedFallbackProps extends CenterProps {
  err: Error | null;
  fallback?: ReactNode;
}

/**
 * Shows fallback UI when an access denied gRPC error is provided
 */
export const AccessDeniedFallback: FC<AccessDeniedFallbackProps> = ({
  err,
  fallback = <AccessDeniedFallbackDefault />,
  children,
  ...rest
}) => {
  if (isAccessDenied(err)) {
    return (
      <Box p={8} {...rest}>
        {fallback}
      </Box>
    );
  }

  return <>{children}</>;
};
