import {
  Product,
  ListProductsResponse,
  Channel,
  ListChannelsResponse,
  ListVersionsResponse,
  Version,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/product/v1/product_registry_pb";
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
  useMutation,
  UseMutationOptions,
} from "react-query";
import { Error as gRPCError } from "grpc-web";
import { channelKeys, productKeys, versionKeys } from "./query-keys";
import {
  getProduct,
  listProducts,
  uploadMediaAsset,
  getChannel,
  listChannels,
  getVersion,
  listVersions,
} from "@utils/hermes-api/product";
import { ListQueryOptions } from "@utils/hermes-api/types";
import {
  getOrgNameFromResource,
  getProductNameFromResource,
} from "@utils/hermes-api/names";
import { Sort } from "./utils";

export const useProductQuery = (
  productName: string,
  queryOptions: UseQueryOptions<Product, gRPCError> = {}
) => {
  return useQuery({
    queryKey: productKeys.single(productName),
    queryFn: () => getProduct(productName),
    ...queryOptions,
  });
};

export interface ProductSort extends Sort<"name"> {}

export const sortProducts = (
  products: Product[],
  { sortField = "name", asc = true }: ProductSort
) => {
  switch (sortField) {
    case "name":
      return [...products].sort((a, b) => {
        const aName = a.getDisplayName() || a.getId();
        const bName = b.getDisplayName() || b.getId();
        return aName.localeCompare(bName) * (asc ? 1 : -1);
      });
    default:
      console.warn(`Unknown product sort field: ${sortField}`);
      return products;
  }
};

export const useProductListQuery = (
  orgName: string,
  filters: ListQueryOptions = {},
  queryOptions: UseQueryOptions<ListProductsResponse, gRPCError> = {}
) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: productKeys.listFilter(orgName, filters),
    queryFn: async () => {
      // NOTE: We're fetching all of the pages here because
      // the API cannot guarantee page sizes. This is a temporary
      // workaround until the API can provide a consistent page size.
      // see: https://dev.azure.com/xboxstudios/Hermes/_workitems/edit/68708
      const products = [];
      let res = await listProducts(orgName, filters);
      products.push(...res.getProductsList());
      while (res.getNextPageToken()) {
        res = await listProducts(orgName, {
          ...filters,
          pageToken: res.getNextPageToken(),
        });
        products.push(...res.getProductsList());
      }
      res.setProductsList(products);

      return res;
    },
    ...queryOptions,
    onSuccess: (res) => {
      const products = res.getProductsList();
      // cache each product
      for (const p of products) {
        queryClient.setQueryData(productKeys.single(p.getName()), p);
      }

      const { sortField, asc } = filters;
      // Locally sort products by explicit sort field, user preference, or
      // default to sorting by display name
      // TODO: This is a temporary solution until we can sort on the server
      res.setProductsList(
        sortProducts(res.getProductsList(), { sortField, asc })
      );

      if (queryOptions.onSuccess) {
        queryOptions.onSuccess(res);
      }
    },
  });
};

export const useProductMutation = <TVariables>(
  mutateOptions: UseMutationOptions<Product, gRPCError, TVariables> = {}
) => {
  const queryClient = useQueryClient();
  return useMutation<Product, gRPCError, TVariables>({
    ...mutateOptions,
    onSuccess: (product, variables, context) => {
      queryClient.setQueryData(productKeys.single(product.getName()), product);

      const orgName = product.getOrgName();
      // set/update individual cached product
      queryClient.invalidateQueries(productKeys.list(orgName), {
        exact: false,
        refetchActive: true,
      });

      if (mutateOptions.onSuccess) {
        mutateOptions.onSuccess(product, variables, context);
      }
    },
  });
};

export const useProductMediaMutation = (
  mutateOptions: UseMutationOptions<
    any,
    gRPCError,
    Parameters<typeof uploadMediaAsset>
  > = {}
) => {
  const queryClient = useQueryClient();
  return useMutation<any, gRPCError, Parameters<typeof uploadMediaAsset>>({
    ...mutateOptions,
    mutationFn: (args) => uploadMediaAsset(...args),
    onSuccess: (res, [media, assetType, productName], context) => {
      const orgName = getOrgNameFromResource(productName);
      queryClient.invalidateQueries(productKeys.single(productName));
      // set/update individual cached product
      queryClient.invalidateQueries(productKeys.list(orgName), {
        exact: false,
        refetchActive: true,
      });

      if (mutateOptions.onSuccess) {
        mutateOptions.onSuccess(res, [media, assetType, productName], context);
      }
    },
  });
};

export const useChannelQuery = (
  channelName: string,
  queryOptions: UseQueryOptions<Channel, gRPCError> = {}
) => {
  return useQuery({
    queryKey: channelKeys.single(channelName),
    queryFn: () => getChannel(channelName),
    ...queryOptions,
  });
};

export interface ChannelSort extends Sort<"name"> {}

export const sortChannels = (
  channels: Channel[],
  { sortField = "name", asc = true }: ChannelSort
) => {
  switch (sortField) {
    case "name":
      return [...channels].sort((a, b) => {
        const aName = a.getDisplayName() || a.getId();
        const bName = b.getDisplayName() || b.getId();
        return aName.localeCompare(bName) * (asc ? 1 : -1);
      });
    default:
      console.warn(`Unknown channel sort field: ${sortField}`);
      return channels;
  }
};

export const useChannelListQuery = (
  productName: string,
  queryOptions: UseQueryOptions<ListChannelsResponse, gRPCError> = {},
  filters: ListQueryOptions = {}
) => {
  const queryClient = useQueryClient();
  const queryKey = channelKeys.listFiltered(productName, filters);
  return useQuery({
    queryKey,
    queryFn: async () => {
      // NOTE: We're fetching all of the pages here because
      // the API cannot guarantee page sizes. This is a temporary
      // workaround until the API can provide a consistent page size.
      // see: https://dev.azure.com/xboxstudios/Hermes/_workitems/edit/68708
      const channels = [];
      let res = await listChannels(productName, filters);
      channels.push(...res.getChannelsList());
      while (res.getNextPageToken()) {
        res = await listChannels(productName, {
          ...filters,
          pageToken: res.getNextPageToken(),
        });
        channels.push(...res.getChannelsList());
      }
      res.setChannelsList(channels);

      return res;
    },
    ...queryOptions,
    onSuccess: (res) => {
      const channels = res.getChannelsList();
      // cache each
      for (const c of channels) {
        queryClient.setQueryData(channelKeys.single(c.getName()), c);
      }

      const { sortField, asc } = filters;
      // Locally sort products by explicit sort field, user preference, or
      // default to sorting by display name
      // TODO: This is a temporary solution until we can sort on the server
      res.setChannelsList(
        sortChannels(res.getChannelsList(), { sortField, asc })
      );

      if (queryOptions.onSuccess) {
        queryOptions.onSuccess(res);
      }
    },
  });
};

export const useChannelMutation = <TVariables>(
  mutationOptions: UseMutationOptions<Channel, gRPCError, TVariables>
) => {
  const queryClient = useQueryClient();
  return useMutation<Channel, gRPCError, TVariables>({
    ...mutationOptions,
    onSuccess: (channel, variables, context) => {
      queryClient.setQueryData(channelKeys.single(channel.getName()), channel);

      const productName = channel.getProductName();
      queryClient.invalidateQueries(channelKeys.list(productName), {
        exact: false,
        refetchActive: true,
      });

      if (mutationOptions.onSuccess) {
        mutationOptions.onSuccess(channel, variables, context);
      }
    },
  });
};

export const useVersionQuery = (
  versionName: string,
  queryOptions: UseQueryOptions<Version, gRPCError> = {}
) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: versionKeys.single(versionName),
    queryFn: () => getVersion(versionName),
    initialData: () => {
      if (versionName) {
        return queryClient.getQueryData(
          versionKeys.list(getProductNameFromResource(versionName))
        );
      }
    },
    ...queryOptions,
  });
};

export type VersionSort = Sort<"createTime">;

export const sortVersions = (
  versions: Version[],
  { sortField, asc }: VersionSort
) => {
  switch (sortField) {
    case "createTime":
      return [...versions].sort((a, b) => {
        const aTime = a.getCreateTime()?.getSeconds() ?? -Infinity;
        const bTime = b.getCreateTime()?.getSeconds() ?? -Infinity;
        const comp = aTime - bTime;
        const ascFactor = !!asc ? 1 : -1;
        return comp * ascFactor;
      });
    default:
      console.warn(`Unknown version sort field: ${sortField}`);
      return versions;
  }
};

export const useVersionListQuery = (
  productName: string,
  queryOptions: UseQueryOptions<ListVersionsResponse, gRPCError> = {},
  filters: ListQueryOptions = {}
) => {
  const queryClient = useQueryClient();
  const queryKey = versionKeys.listFiltered(productName, filters);
  return useQuery({
    queryKey,
    queryFn: async () => {
      // NOTE: We're fetching all of the pages here because
      // the API cannot guarantee page sizes. This is a temporary
      // workaround until the API can provide a consistent page size.
      // see: https://dev.azure.com/xboxstudios/Hermes/_workitems/edit/68708
      const versions = [];
      let res = await listVersions(productName, filters);
      versions.push(...res.getVersionsList());
      while (res.getNextPageToken()) {
        res = await listVersions(productName, {
          ...filters,
          pageToken: res.getNextPageToken(),
        });
        versions.push(...res.getVersionsList());
      }
      res.setVersionsList(versions);

      return res;
    },
    initialData: () => {
      if (productName) {
        return queryClient.getQueryData(versionKeys.list(productName));
      }
    },
    ...queryOptions,
    onSuccess: (res) => {
      const versions = res.getVersionsList();
      // cache each version
      for (const v of versions) {
        queryClient.setQueryData(versionKeys.single(v.getName()), v);
      }

      // TODO: This is a temporary solution until we can sort on the server
      const { sortField, asc } = filters.sort || {};
      if (sortField) {
        res.setVersionsList(
          sortVersions(res.getVersionsList(), {
            sortField: sortField || "createTime",
            asc: asc ?? false,
          })
        );
      }

      if (queryOptions.onSuccess) {
        queryOptions.onSuccess(res);
      }
    },
  });
};

export const sortVersionListByCreateTime = (res: ListVersionsResponse) => {
  const sortedVersions = res
    .getVersionsList()
    .sort(
      (a, b) =>
        (b.getCreateTime()?.getSeconds() || 0) -
        (a.getCreateTime()?.getSeconds() || 0)
    );
  res.setVersionsList(sortedVersions);
  return res;
};

export const useVersionMutation = <TVariables>(
  mutationOptions: UseMutationOptions<Version, gRPCError, TVariables> = {}
) => {
  const queryClient = useQueryClient();
  return useMutation<Version, gRPCError, TVariables>({
    ...mutationOptions,
    onSuccess: (version, variables, context) => {
      queryClient.setQueryData(versionKeys.single(version.getName()), version);
      const productName = version.getProductName();
      queryClient.invalidateQueries(versionKeys.list(productName), {
        exact: false,
        refetchActive: true,
      });

      if (mutationOptions.onSuccess) {
        mutationOptions.onSuccess(version, variables, context);
      }
    },
  });
};
