import {
  Flex,
  FlexProps,
  Link as ChakraLink,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import UserMenu from "@components/header/UserMenu";
import Logo from "@components/Logo";
import HeaderBreadcrumbs from "@components/header/HeaderBreadcrumbs";
import { getCurrentEnvDisplayName } from "@env/envrionment";
import { EnvironmentBadge } from "@env/EnvironmentBadge";

const envName = getCurrentEnvDisplayName();

export interface HeaderProps extends FlexProps {}

function Header({ ...rest }: HeaderProps) {
  const boxShadow = useColorModeValue("sm", "md");
  return (
    <Flex
      w="full"
      h="full"
      px={4}
      py={2}
      justifyContent="space-between"
      alignItems="center"
      backgroundColor="primary"
      boxShadow={boxShadow}
      // We want the header and user menu to always be on top...
      zIndex="10"
      {...rest}
    >
      <Flex mr={2} align="center">
        <ChakraLink as={Link} display="flex" to="/orgs">
          <Logo height={10} width={10} objectFit="contain" alt="Hermes" />
          <Text
            ml={2}
            fontSize="2xl"
            fontWeight="bold"
            _hover={{ textDecoration: "none" }}
          >
            Hermes
          </Text>
        </ChakraLink>
        {!!envName && (
          <EnvironmentBadge
            ml={2}
            size="sm"
            fontSize="x-small"
            userSelect="none"
          />
        )}
      </Flex>
      <Flex mx={4} flex="1" justify="space-between" align="center">
        <Flex flex="1" fontSize={["xs", "xs", "sm", "md"]}>
          <HeaderBreadcrumbs />
        </Flex>
      </Flex>
      <Flex justifyContent="flex-end" alignItems="center" maxWidth="md">
        <UserMenu />
      </Flex>
    </Flex>
  );
}

export default Header;
