import * as React from "react";
import {
  useColorMode,
  IconButton,
  ButtonGroup,
  ButtonGroupProps,
} from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";

type ColorModeSwitcherProps = ButtonGroupProps;

export const ColorModeSwitcher: React.FC<ColorModeSwitcherProps> = (props) => {
  const { setColorMode, colorMode } = useColorMode();

  return (
    <ButtonGroup isAttached fontSize="lg" {...props}>
      <IconButton
        onClick={() => setColorMode("light")}
        icon={<FaSun />}
        aria-label="Switch to light mode"
        isActive={colorMode === "light"}
        flex="1"
      />
      <IconButton
        fontSize="lg"
        onClick={() => setColorMode("dark")}
        icon={<FaMoon />}
        aria-label="Switch to dark mode"
        isActive={colorMode === "dark"}
        flex="1"
      />
    </ButtonGroup>
  );
};
