import { Tag, TagProps } from "@chakra-ui/react";
import { getHermesEnvironment, HermesEnvironmentConfig } from "./envrionment";

export interface EnvironmentBadgeProps extends TagProps {
  env?: HermesEnvironmentConfig;
}

/**
 * Displays a badge for the supplied Hermes environment.
 * If no environmnent is supplied it will use the currently
 * selected environment.
 * @param param0
 * @returns
 */
export const EnvironmentBadge = ({
  env = getHermesEnvironment(),
  ...rest
}: EnvironmentBadgeProps) => {
  return (
    <Tag
      fontWeight="semibold"
      textTransform="capitalize"
      {...rest}
      title={env.hermesApi.endpoint}
      colorScheme={env.info.colorScheme}
    >
      {env.name}
    </Tag>
  );
};
