import { createRoot } from "react-dom/client";
import { App } from "./App";
import { registerMsalEventHandler, msalInstance } from "@auth/auth";

const container = document.getElementById("root");

async function initializeApp() {
  await msalInstance.initialize().then(() => {
    registerMsalEventHandler(msalInstance);
  });

  const root = createRoot(container!);
  root.render(<App pca={msalInstance} />);
}

initializeApp();
