import { AccountPicker } from "@auth/AccountPicker";
import { AccountInfo } from "@azure/msal-common";
import { useMsal } from "@azure/msal-react";

export interface ActiveAccountPickerProps {}

export const ActiveAccountPicker = () => {
  const msal = useMsal();
  const aa = msal.instance.getActiveAccount();
  const setActiveAccount = (a: AccountInfo) => {
    msal.instance.setActiveAccount(a);
    window.location.reload();
  };
  return <AccountPicker activeAccount={aa} onChange={setActiveAccount} />;
};
