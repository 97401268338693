import { Box, Flex, Skeleton, Text } from "@chakra-ui/react";
import { getGraphClient } from "@utils/graph/graph";
import { useQuery } from "react-query";
import Persona from "./Persona";
import { useMsal } from "@azure/msal-react";
import { defaultRequest } from "@auth/auth";

const useCurrentUser = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const accounts = instance.getAllAccounts();

  return useQuery(
    ["graph", "me", activeAccount?.homeAccountId],
    () =>
      getGraphClient({
        ...defaultRequest,
        account: activeAccount || accounts[0],
      })
        .api("/me")
        .get(),
    {
      enabled: !!activeAccount,
    }
  );
};

const useOrganization = () => {
  const msal = useMsal();
  const activeAccount = msal.instance.getActiveAccount();
  const accounts = msal.accounts;

  return useQuery(
    ["graph", "organization", "current", activeAccount?.homeAccountId],
    () =>
      getGraphClient({
        ...defaultRequest,
        account: activeAccount || accounts[0],
      })
        .api("/organization")
        .select("displayName, id")
        .get(),
    {
      select: (org) => org.value[0],
      enabled: !!activeAccount,
    }
  );
};

const CurrentUser = () => {
  const msal = useMsal();
  const activeAccount = msal.instance.getActiveAccount();
  const { data: user } = useCurrentUser();
  const { data: org } = useOrganization();

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Persona
        userId={user?.id || activeAccount?.localAccountId || ""}
        email={user?.userPrincipalName || activeAccount?.username || ""}
        tenantId={org?.id || activeAccount?.tenantId || ""}
        text={user?.displayName || activeAccount?.name || ""}
        size="sm"
      />
      <Box mx={2} minW={20}>
        <Skeleton isLoaded={!!user || !!activeAccount} w="full">
          <Text textAlign="left" fontSize={14} minH={4}>
            {user?.displayName || activeAccount?.name}
          </Text>
        </Skeleton>

        <Skeleton isLoaded={!!org} w="full">
          <Text textAlign="left" fontWeight={6} fontSize={12} minH={3}>
            {org?.displayName}
          </Text>
        </Skeleton>
      </Box>
    </Flex>
  );
};

export default CurrentUser;
