import { getAccessToken } from "@auth/auth";
import {
  GetOrganizationRequest,
  Organization,
  SearchOrganizationsRequest,
  UpdateOrganizationRequest,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/directory/v1/organizations_pb";
import { FieldMask } from "@xboxstudios/hermes-apis/google/protobuf/field_mask_pb";
import { HermesApiPermissions } from "./api-permissions";
import { getHermesClient } from "./client";
import { ListQueryOptions } from "./types";

const getOrganizationPromiseClient = () => {
  return getHermesClient().organizationsPromiseClient;
};

const getToken = async (scopes: string[]) => await getAccessToken({ scopes });

const getMetadata = async (
  scopes: string[],
  additionalFields?: { [key: string]: string }
) => {
  const token = await getToken(scopes);
  return {
    Authorization: `Bearer ${token}`,
    ...additionalFields,
  };
};

export const searchOrganizations = async (options: ListQueryOptions) => {
  const client = getOrganizationPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.directory]);
  const req = new SearchOrganizationsRequest();
  if (options.pageSize) {
    req.setPageSize(options.pageSize);
  }
  if (options.pageToken) {
    req.setPageToken(options.pageToken);
  }
  if (options.showDeleted) {
    req.setQuery("state = DELETED OR state = ACTIVE");
  }
  return client.searchOrganizations(req, metadata);
};

export const getOrganization = async (orgName: string) => {
  const client = getOrganizationPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.directory]);
  const req = new GetOrganizationRequest();
  req.setName(orgName);
  return client.getOrganization(req, metadata);
};

export const updateOrganization = async (
  orgEdits: Organization,
  updatePaths: string[]
) => {
  const client = getOrganizationPromiseClient();
  const metadata = await getMetadata([HermesApiPermissions.directory]);
  const req = new UpdateOrganizationRequest();
  const updateMask = new FieldMask();
  if (updatePaths) {
    updateMask.setPathsList(updatePaths);
  }
  req.setOrganization(orgEdits);
  // @ts-ignore
  req.setUpdateMask(updateMask);
  return client.updateOrganization(req, metadata);
};
