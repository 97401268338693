import {
  Device,
  SearchDevicesResponse,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/device/v1/device_registry_pb";
import {
  QueryClient,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { deviceKeys, operationKeys } from "./query-keys";
import { Error as gRPCError } from "grpc-web";
import { Empty } from "google-protobuf/google/protobuf/empty_pb";
import {
  ListOperationsResponse,
  Operation,
} from "@xboxstudios/hermes-apis/xboxstudios/hermes/operations/v1/operations_pb";
import {
  getDevice,
  getDeviceByUserCode,
  getDeviceList,
  sendDeviceCommand,
} from "@utils/hermes-api/device";
import { getOperation, listOperations } from "@utils/hermes-api/operation";
import { ListQueryOptions } from "@utils/hermes-api/types";
import { getDeviceNameFromResource } from "@utils/hermes-api/names";

export const useDeviceQuery = (
  deviceName: string,
  queryOptions: UseQueryOptions<Device, gRPCError> = {}
) => {
  return useQuery({
    queryKey: deviceKeys.single(deviceName),
    queryFn: () => getDevice(deviceName),
    ...queryOptions,
  });
};

export const useDeviceCodeQuery = (
  deviceCode: string,
  queryOptions: UseQueryOptions<Device, gRPCError> = {}
) => {
  const queryClient = useQueryClient();
  return useQuery<Device, gRPCError>({
    queryKey: deviceKeys.code(deviceCode),
    queryFn: () => getDeviceByUserCode(deviceCode),
    ...queryOptions,
    onSuccess: (device) => {
      // cache the device
      if (device) {
        queryClient.setQueryData(deviceKeys.single(device.getName()), device);
      }

      if (queryOptions.onSuccess) {
        queryOptions.onSuccess(device);
      }
    },
  });
};

export const useDeviceListQuery = (
  filters: ListQueryOptions = {},
  queryOptions: UseQueryOptions<SearchDevicesResponse, gRPCError> = {}
) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: deviceKeys.listFiltered(filters),
    queryFn: async () => {
      // NOTE: We're fetching all of the pages here because
      // the API cannot guarantee page sizes. This is a temporary
      // workaround until the API can provide a consistent page size.
      // see: https://dev.azure.com/xboxstudios/Hermes/_workitems/edit/68708
      const devices = [];
      let res = await getDeviceList(filters);
      devices.push(...res.getDevicesList());
      while (res.getNextPageToken()) {
        res = await getDeviceList({
          ...filters,
          pageToken: res.getNextPageToken(),
        });
        devices.push(...res.getDevicesList());
      }
      // sort devices by register time
      res.setDevicesList(
        devices.sort(
          (a, b) =>
            (b.getRegisterTime()?.getSeconds() || 0) -
            (a.getRegisterTime()?.getSeconds() || 0)
        )
      );

      return res;
    },
    ...queryOptions,
    onSuccess: (res) => {
      const devices = res.getDevicesList();
      // cache each device individually
      for (const d of devices) {
        queryClient.setQueryData(deviceKeys.single(d.getName()), d);
      }

      if (queryOptions.onSuccess) {
        queryOptions.onSuccess(res);
      }
    },
  });
};

export const useDeviceMutation = <TVariables>(
  mutateOptions: UseMutationOptions<Device, gRPCError, TVariables> = {}
) => {
  const queryClient = useQueryClient();
  return useMutation<Device, gRPCError, TVariables>({
    ...mutateOptions,
    onSuccess: (device, variables, context) => {
      device.hasDeleteTime();
      // set/update individual cached device
      // queryClient.setQueryData(deviceKeys.single(device.getName()), device);
      queryClient.invalidateQueries(deviceKeys.single(device.getName()), {
        refetchActive: true,
      });

      // TODO: Determine what a good query key is for this scenario
      queryClient.invalidateQueries(deviceKeys.allLists, {
        exact: false,
        refetchActive: true,
      });

      if (mutateOptions.onSuccess) {
        mutateOptions.onSuccess(device, variables, context);
      }
    },
  });
};

export const useDeviceCommandMutation = (
  mutateOptions: UseMutationOptions<
    Operation,
    gRPCError,
    Parameters<typeof sendDeviceCommand>
  > = {}
) => {
  const queryClient = useQueryClient();
  return useMutation<
    Operation,
    gRPCError,
    Parameters<typeof sendDeviceCommand>
  >({
    mutationFn: (args) => sendDeviceCommand(...args),
    ...mutateOptions,
    onSuccess: (operation, variables, context) => {
      queryClient.setQueryData(
        operationKeys.single(operation.getName()),
        operation
      );
      queryClient.invalidateQueries(operationKeys.list(variables[0]), {
        exact: false,
        refetchActive: true,
      });
      if (mutateOptions.onSuccess) {
        mutateOptions.onSuccess(operation, variables, context);
      }
    },
  });
};

export const useOperationQuery = (
  operationName: string,
  queryOptions: UseQueryOptions<Operation, gRPCError> = {}
) => {
  return useQuery({
    queryKey: operationKeys.single(operationName),
    queryFn: () => getOperation(operationName),
    ...queryOptions,
  });
};

export const useOperationListQuery = (
  resourceOwnerName: string,
  queryOptions: UseQueryOptions<ListOperationsResponse, gRPCError> = {},
  requestOptions: ListQueryOptions = {}
) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: operationKeys.listFiltered(resourceOwnerName, requestOptions),
    queryFn: async () => {
      // NOTE: We're fetching all of the pages here because
      // the API cannot guarantee page sizes. This is a temporary
      // workaround until the API can provide a consistent page size.
      // see: https://dev.azure.com/xboxstudios/Hermes/_workitems/edit/68708
      const operations = [];
      let res = await listOperations(resourceOwnerName, requestOptions);
      operations.push(...res.getOperationsList());
      while (res.getNextPageToken()) {
        res = await listOperations(resourceOwnerName, {
          ...requestOptions,
          pageToken: res.getNextPageToken(),
        });
        operations.push(...res.getOperationsList());
      }
      res.setOperationsList(operations);

      return res;
    },
    ...queryOptions,
    onSuccess: (res) => {
      const operations = res.getOperationsList();
      // cache each
      for (const o of operations) {
        queryClient.setQueryData(operationKeys.single(o.getName()), o);
      }

      if (queryOptions.onSuccess) {
        queryOptions.onSuccess(res);
      }
    },
  });
};

export const sortOperationListByDate = (res: ListOperationsResponse) => {
  const sortedOps = res.getOperationsList().sort(
    (a, b) =>
      // @ts-ignore
      b.getUpdateTime()?.getSeconds() - a.getUpdateTime()?.getSeconds()
  );
  res.setOperationsList(sortedOps);
  return res;
};

export const useOperationMutation = (
  operationName: string,
  mutationOptions: UseMutationOptions<Empty, gRPCError> = {}
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...mutationOptions,
    onSuccess: (...args) => {
      queryClient.invalidateQueries(operationKeys.single(operationName), {
        exact: false,
        refetchActive: true,
      });
      if (mutationOptions.onSuccess) {
        mutationOptions.onSuccess(...args);
      }
    },
  });
};

export const removeOperationFromListCache = (
  operationName: string,
  queryClient: QueryClient
) => {
  const deviceName = getDeviceNameFromResource(operationName);
  const key = operationKeys.list(deviceName);
  const opList = queryClient.getQueryData<ListOperationsResponse>(key);
  if (opList) {
    const list = opList
      .getOperationsList()
      .filter((o) => o.getName() !== operationName);
    opList.setOperationsList(list);
    queryClient.setQueryData(key, opList);
  }
};
