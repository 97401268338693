import { Link, LinkProps } from "@chakra-ui/react";

export interface MSDataPrivacyLinkProps extends LinkProps {}

// See https://microsoft.sharepoint.com/sites/CELAWeb-Compliance/SitePages/regulatory-compliance-privacy-and-security-data-protection-notice.aspx#how-do-i-use-the-notice
export const MSDataPrivacyLink = ({ ...rest }: MSDataPrivacyLinkProps) => {
  return (
    <Link
      href="https://privacy.microsoft.com/en-US/data-privacy-notice"
      {...rest}
    >
      MS Data Privacy Notice
    </Link>
  );
};
